<template>
  <div>
    <section class="vh-100">
      <div class="container mt-5 h-90">
        
        <b-row class="text-center mt-4 mb-4">
          <b-col>
            <h1 style="font-size: 50px; font-weight: 900; color: white">
              <span>Login</span>
            </h1>
          </b-col>
        </b-row>
      </div>
      <div class="container h-50">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card" style="border-radius: 1rem">
              <b-row class="text-center mt-5">
                <b-col>
                  <img
                    src="../assets/images/webpatrol.png"
                    alt="Web Patrol"
                    style="height: 13vh"
                  />
                </b-col>
              </b-row>
              <div class="card-body p-5 text-center">
                <form action="" @submit.prevent="Login()">
                  <div class="form-outline mb-4">
                    <label class="form-label" for="typeEmailX-2"
                      >Enter Your Email</label
                    >
                    <input
                      v-model="input.email"
                      type="email"
                      id="typeEmailX-2"
                      class="form-control form-control-lg"
                      placeholder="e.g info@example.com"
                    />
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="typePasswordX-2"
                      >Enter Your Password</label
                    >
                    <b-input-group>
                      <b-form-input
                        v-model="input.password"
                        v-on:keyup.enter="Login()"
                        type="password"
                        id="newpassword"
                        class="form-control form-control-lg"
                        placeholder="**********"
                      />
                      <b-input-group-append
                        is-text
                        @click="togglePassword('newpassword')"
                      >
                        <i
                          v-show="showPassword == true"
                          class="icofont icofont-eye"
                          id="togglePassword"
                          title="hide password"
                        ></i>
                        <i
                          v-show="showPassword == false"
                          class="icofont icofont-eye-blocked"
                          id="togglePassword"
                          title="show password"
                        ></i>
                      </b-input-group-append>
                    </b-input-group>
                  </div>

                  <div class="form-outline mb-4 text-right">
                    <router-link tag="a" to="forgetpassword"
                      >Forget password?</router-link
                    >
                  </div>

                  <button class="btn btn-lg btn-block" type="submit" style="background-color: blue;">
                    Sign in
                  </button>
                </form>
                <!-- <div class="mt-2 text-center p-2 google_btn">
                  <a :href="googleLogin">
                    <img
                      width="15px"
                      style="margin-bottom: 3px; margin-right: 5px"
                      alt="Google login"
                      src="../assets/images/google_logo.webp"
                    />
                    Sign in with Google
                  </a>
                </div> -->
                <hr class="my-4" />

                <div class="form-outline mb-4 text-center">
                  <router-link
                    style="color: blue"
                    tag="a"
                    to="/auth/register"
                    ><h6>Join now, create your account</h6></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import UserController from "../../services/UserService";
import LoginController from '../../services/LoginControlService'
import Userauth from "../auth/js/index";
import Config from "../../services/config";
export default {
  name: "login",
  data() {
    return {
      showPassword: false,
      verify_password: "",
      input: { email: "", password: "", source: "", clickid: "" },
      auth: "",
      loading: false,
      googleLogin: "",
    };
  },
  mounted() {
    // if (this.$route.query.action) {
    //   this.loginCheck();
    // }
    if (Userauth.isAuthenticated() && Userauth.checAlive()) {
      let user = JSON.parse(window.localStorage.getItem("userinfo"));
      if (user.role == "manager") {
        this.$router.replace("/manager/users");
      } else {
        this.$router.replace("/home");
      }
      this.$toasted.show("Successfully Logged In to - " + user.email, {
        theme: "outline",
        position: "bottom-center",
        type: "success",
        duration: 2000,
      });
    }
    if (this.$root.$data.clickid) {
      this.input.clickid = this.$root.$data.clickid;
    }
    if (this.$root.$data.source) {
      this.input.source = this.$root.$data.source;
    }

    this.googleLogin =
      Config.googleLogin +
      "?source=" +
      this.input.source +
      "&clickid=" +
      this.input.clickid;
  },
  methods: {
    // async loginCheck() {
    //   let response = await UserController.acceptCall(this.input);
    //   if (response.result) {
    //     if (response.message.isactive) {
    //       Userauth.localLogin(response.message);
    //       this.$router.replace("/app/monitor");
    //     }
    //   }
    // },
    async Login() {
      if (this.validate()) {
        // this.$router.replace("/");
        this.loading = true;
        let response = await LoginController.login(this.input);
        if (response.result) {
          if (response.message.isactive) {
            Userauth.localLogin(response.message);
            this.$toasted.show("Successfully Logged In", {
              theme: "outline",
              position: "bottom-center",
              type: "success",
              duration: 2000,
            });
            if (response.message.role == "manager") {
              this.$router.replace("/manager/users");
            } else {
              this.$router.replace("/home");
            }
            this.auth = setInterval(() => {
              // this.Auth();
            }, 120000);
          } else {
            // this.$router.replace("/auth/verification");
            this.$toasted.show(
              "Your Account is not Activated. Please contact to manager",
              {
                theme: "outline",
                position: "bottom-center",
                type: "error",
                duration: 4000,
              }
            );
          }
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          // Userauth.Logout();
        }
      }
      this.loading = false;
    },
    async Auth() {
      // let response = await UserController.authenticate();
      // if (response != true) {
      //   clearInterval(this.auth);
      //   Userauth.Logout();
      // }
    },
    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },

    validate() {
      if (this.validateEmail(this.input.email)) {
        if (this.input.email == "" && this.input.password == "") {
          this.$toasted.show(
            "Please enter a valid email address and password",
            {
              theme: "outline",
              position: "bottom-center",
              type: "error",
              duration: 2000,
            }
          );
          return false;
        }
        return true;
      } else {
        this.$toasted.show("Invalid Email", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      }
    },
    togglePassword(id) {
      const password = document.getElementById(id);
      this.showPassword = this.showPassword === false ? true : false;
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },
  },
};
</script>

<style>
body {
  background-image: url("../assets/images/login-bg.jpg") !important;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 100%;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 15px 10px #000000 !important;
}
button {
  background-color: #f7981e;
  color: #fefeff;
}
</style>